import React, { useEffect, useState } from 'react'
import Moudles from '../modules'
import { action_fetch_home } from '../../../../../store/actions/pages/home'

import { useSelector } from 'react-redux'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import Footer from '../../../layout/fragments/footer'
import { useStore } from 'react-redux'
import AlertPopup from '../../../layout/fragments/alert-popup'
// import GiftPickMask from '../../../layout/fragments/gift-pick-mask/giftPickMask'
import { getLuckStarShow } from '../../../../../api'
import BlackCouponAlert from '../../../layout/fragments/black-coupon-alert'


const HomePage = props => {

    useStyles(classes)
    const store = useStore()
    const pageHome = useSelector((state) => state.pageHome)
    const pageGlobal = useSelector((state) => state.global)
    const currentPageData = pageHome?.pages?.find(p => p.id === (pageHome?.currentPage || '1'))

    const { website } = pageGlobal

    const [showGiftPick, setShowGiftPick] = useState(false)
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (window.__INITIAL_STATE__) {
            delete window.__INITIAL_STATE__
        } else {
            if (!pageHome?.pages || pageHome?.pages.length < 1 || pageHome?.currentPage !== website) {
                HomePage.fetchData(store, null, { website: website || '1' }, null)
            }
            window.currentPage = 'home'
            document.title = `Women\'s Fashion Online Shopping`
            if (window.GeekoSensors) {
                window.GeekoSensors.Quick('autoTrackSinglePage', {
                    page_sort: 'home',
                    page_title: '首页',
                    page_content: 'home'
                })
            }
        }
        // getGiftMaskShow()
    }, [])

    // const getGiftMaskShow = async () => {
    //     const res = await getLuckStarShow()
    //     if (res?.code == 200 && res?.result) {
    //         setShowGiftPick(true)
    //     } else {
    //         setShowAlert(true);
    //     }
    // }

    // const giftMaskHide = () => {
    //     setShowGiftPick(false)
    // }


    return <div style={{ overflowX: 'hidden' }}>
        <Moudles data={currentPageData?.page?.modules} sensors={
            {
                title: `home-${currentPageData?.refId}`,
            }
        } />

        {/* {
            showGiftPick && <GiftPickMask onClose={() => giftMaskHide()} />
        } */}

        <BlackCouponAlert />

        <AlertPopup />

        <Footer />

    </div>
}

HomePage.fetchData = async (store, params, search, headers) => {
    const website = search?.website
    const promises = [
        store.dispatch(action_fetch_home(website, search?.previewCode, headers)),
    ]
    const data = await Promise.all(promises)
    return data
}

export default HomePage